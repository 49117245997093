import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import company_logo from '../assets/images/logo/kimitech-logo@1x.png'

const Header = (props) => (
  <header id="header" className="alt">
    <Link to="/" className="logo">
      <img
        style={{ maxHeight: 40, verticalAlign: 'middle' }}
        src={company_logo}
        alt="KIMITECH LOGO"
      />
    </Link>
    <nav className="nav-menu-bar">
      <Link to="/" className="menu-link-button">
        HOME
      </Link>
      <Link to="/about_us" className="menu-link-button">
        ABOUT US
      </Link>
      <Link to="/testing" className="menu-link-button">
        TESTING
      </Link>
      <Link to="/consulting" className="menu-link-button">
        CONSULTING
      </Link>
      <Link to="mailto:info@kimitech.com.my" className="menu-link-button">
        CONTACT US
      </Link>
    </nav>
    <nav className="nav-menu-button">
      <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">
        Menu
      </a>
    </nav>
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Header
